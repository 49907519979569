<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    dataCollection: {
      type: Object,
      default: null,
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              display: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
    },
  },
  mounted() {
    this.renderChart(this.dataCollection, this.options);
  },
  /*watch() {
    this.renderChart(this.dataCollection, this.options);
  },*/
};
</script>