<template>
  <section>
    <div class="container-navbar navbar is-fixed-top">
      <router-link to="/NavBar">
        <img
          alt="logo cookie.menu"
          src="../assets/images/logo-web-transparent.svg"
        />
      </router-link>
      <div id="app" class="container">
        <div id="app" class="container">
          <b-navbar centered>
            <template #start>
              <b-navbar-item tag="div" class="title-cookie">
                <h1>Cookie menu - Espace administrateur</h1>
              </b-navbar-item>
            </template>
            <template #end>
              <b-navbar-item tag="div">
                <router-link to="/NavBar">
                  <div class="buttons">
                    <a class="button is-primary">
                      <strong>Home</strong>
                    </a>
                    <a @click="logOutApp" class="button is-light"> Log out</a>
                  </div>
                </router-link>
              </b-navbar-item>
            </template>
          </b-navbar>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {};
  },
  methods: {
    logOutApp() {
      this.$keycloak.logout();
      localStorage.clear();
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/main.scss';

img {
  width: 70px;
  height: 70px;
}

.title-cookie {
  color: $primary;
}

.button.is-primary {
  background-color: $primary;
}

.button.is-light {
  border-color: lightgrey;
}

.container-navbar {
  box-shadow: 5px 0px 13px 3px #c3c3c1;
  background-color: $navbar;
  z-index: 1111;
}

.navbar,
.navbar-menu,
.navbar-start,
.navbar-end {
  background-color: $navbar;
}

.navbar[data-v-4295d220],
.navbar-menu[data-v-4295d220],
.navbar-start[data-v-4295d220],
.navbar-end[data-v-4295d220] {
  background-color: whitesmoke;
  display: flex;
  justify-content: space-evenly;
}

@media screen and (min-width: 768px) {
  
}
</style>

