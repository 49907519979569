<template>
  <div class="card-cookie">
    <slot></slot>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.card-cookie {
  margin: 2rem auto;
  width: 72rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 20px;
  background-color: white;
 }
</style>
