<template>
  <l-marker :key="marker.id" :lat-lng="marker.coordinates" @click="openRestaurant">
    <l-tooltip :content="marker.name"></l-tooltip>
    <l-icon ref="icon">
      <img
        class="restaurant-icon"
        :src="'https://img.icons8.com/plasticine/100/000000/cookie.png'"
      />
    </l-icon>
  </l-marker>
</template>

<script>
import { LIcon, LMarker, LTooltip } from 'vue2-leaflet';

let link = "https://test.app.cookie.menu/restaurants/";

export default {
  components: { LIcon, LMarker, LTooltip },
  props: {
    marker: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    openRestaurant(e) {
      window.open(link + this.marker.id)
    }
  }
};
</script>

<style>
.restaurant-icon {
  height: 50px;
  width: auto;
}
</style>