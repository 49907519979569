<template>
  <section>
    <nav-bar></nav-bar>
    <side-navbar></side-navbar>
    <calendar-cookie-2 @search-result="getData" :details="details">
    </calendar-cookie-2>
    <table-cookie
      v-if="displayTableRestaurant"
      :nbRestaurants="results && results.nbRestaurants"
      :isActive="isActive"
      :users_count="results && results.users_count"
      :users_confirmed_count="results && results.users_confirmed_count"
      :menus_count="results && results.menus_count"
      :menus_published_count="results && results.menus_published_count"
    ></table-cookie>
    <table-details
      v-if="displayTableRestaurant"
      :details="details"
    ></table-details>
    <graph-charts
      v-if="displayTableRestaurant"
      :details="details"
    ></graph-charts>
    <map-component
      v-if="displayTableRestaurant"
      :details="details"
    ></map-component>
    <footer-cookie></footer-cookie>
  </section>
</template>

<script>
const API_URL = process.env.VUE_APP_API_URL;

import NavBar from '../components/NavBar.vue';
import CalendarCookie2 from '../components/CalendarCookie2.vue';
import TableCookie from '../components/TableCookie.vue';
import TableDetails from '../components/TableDetails.vue';
import { getDataCounts } from '../services/Restaurants.js';
import { getFullData } from '../services/Restaurants.js';
import { getFullContacts } from '../services/Contact.js';
import GraphCharts from '../components/GraphCharts.vue';
import SideNavbar from '../components/SideNavbar.vue';
import FooterCookie from '../components/FooterCookie.vue';
import MapComponent from '../components/MapComponent.vue';

export default {
  props: ['KeyCloak', 'options'],
  components: {
    TableCookie,
    CalendarCookie2,
    TableDetails,
    GraphCharts,
    SideNavbar,
    NavBar,
    FooterCookie,
    MapComponent
  },
  data() {
    return {
      results: [],
      dataGraph: {
        graph: {
          caption: 'Menus',
          subCaption: '',
          xAxisName: 'menus',
          yAxisName: 'meals',
          numberSuffix: '',
          theme: 'fusion',
        },
        data: [],
      },
      contacts: [],
      searchDates: null,
      details: [],
      dataExport: [],
      displayTableRestaurant: false,
      isActive: false,
    };
  },
  methods: {
    getData({ value1, value2 }) {
      this.isActive = true;
      this.displayTableRestaurant = true;
      getFullData(value1, value2, localStorage.getItem('vue-token')).then(
        (json) => {
          this.details = json;
          console.log('details', this.details);
        },
      );
      this.details = [];

      getDataCounts(value1, value2, localStorage.getItem('vue-token')).then(
        (json) => {
          this.results = json;
          // console.log('results', this.results);
        },
      );
      this.results = [];
    },
    getContacts({ value1, value2 }) {
      this.isActive = true;
      // console.log(value1, value2);
      this.searchDates = {
        start: value1,
        end: value2,
      };
      this.displayTableRestaurant = true;
      getFullContacts(
        this.searchDates.start,
        this.searchDates.end,
        localStorage.getItem('vue-token'),
      ).then((json) => {
        this.contacts = json;
        // console.log('contacts', this.contacts);
      });
    },
  },
};
</script>

<style>
body,
html {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1 {
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
}
</style>




   





   