const API_URL = `${process.env.VUE_APP_API_URL}`;

export function getDataCounts(dateFrom, dateTo, token) {
    return fetch(`${API_URL}/restaurants/counts?startDate=${dateFrom}&endDate=${dateTo}`, {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
        }),
      })
        .then((response) => response.json())
}

export function getFullData(dateFrom, dateTo, token){
    return  fetch(
        `${API_URL}/restaurants/?startDate=${dateFrom}&endDate=${dateTo}`,
        {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
          }),
        },
      )
        .then((response) => response.json());
}