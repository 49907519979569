import Vue from 'vue';
import Vuex from 'vuex';
import graphData from './graphData.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        a: graphData,
    }
})