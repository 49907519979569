<template>
  <div>
    <div class="container">
      <card-cookie>
        <h1 class="color">Données globales</h1>
        <table class="table">
          <thead>
            <tr>
              <td class="td-display">Restaurants</td>
              <td class="resultat-td link-to-details">
                {{ nbRestaurants }}
              </td>
            </tr>
            <tr>
              <td class="td-display">
                Nombre d'utilisateurs
                <img
                  class="img-td"
                  src="../assets/images/point-inte.png"
                  @click="getHelperMessage1"
                />
              </td>
              <p v-if="isVisible1" class="message td1">
                Nombre d'utilisateurs qui ont demandé à s'inscrire
              </p>
              <td class="resultat-td">{{ users_count }}</td>
            </tr>
            <tr>
              <td class="td-display">
                Nombre d'utilisateurs confirmés
                <img
                  class="img-td"
                  src="../assets/images/point-inte.png"
                  @click="getHelperMessage2"
                />
              </td>
              <p v-if="isVisible2" class="message td2">
                Nombre d'utilisateurs qui ont validé leur adresse email
              </p>
              <td class="resultat-td">{{ users_confirmed_count }}</td>
            </tr>
            <tr>
              <td class="td-display">
                Menus crées
                <img
                  class="img-td"
                  src="../assets/images/point-inte.png"
                  @click="getHelperMessage3"
                />
              </td>
              <p v-if="isVisible3" class="message td3">
                Nombre de menus crées par le restaurateur
              </p>
              <td class="resultat-td">{{ menus_count }}</td>
            </tr>
            <tr>
              <td class="td-display">
                Menus publiés
                <img
                  class="img-td"
                  src="../assets/images/point-inte.png"
                  @click="getHelperMessage4"
                />
              </td>
              <p v-if="isVisible4" class="message td4">
                Nombre de menus visible par le client
              </p>
              <td class="resultat-td">{{ menus_published_count }}</td>
            </tr>
          </thead>
        </table>
      </card-cookie>
    </div>
  </div>
</template>

<script>
import CardCookie from './CardCookie.vue';
export default {
  components: { CardCookie },
  props: {
    nbRestaurants: {
      type: Number,
    },
    users_count: {
      type: Number,
    },
    users_confirmed_count: {
      type: Number,
    },
    menus_count: {
      type: Number,
    },
    menus_published_count: {
      type: Number,
    },
    isActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      isVisible1: false,
      isVisible2: false,
      isVisible3: false,
      isVisible4: false,
    };
  },
  methods: {
    getHelperMessage1() {
      this.isVisible1 = !this.isVisible1;
    },
    getHelperMessage2() {
      this.isVisible2 = !this.isVisible2;
    },
    getHelperMessage3() {
      this.isVisible3 = !this.isVisible3;
    },
    getHelperMessage4() {
      this.isVisible4 = !this.isVisible4;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/main.scss';

.color {
  background-color: $navbar;
  margin-bottom: 25px;
  border-radius: 5px;
}

.title {
  text-align: center;
}

.container {
  width: 85%;
  margin: auto;
}

.table-style {
  width: 25%;
  margin: auto;
  border: 1px solid grey;
  border-radius: 5px;
  margin-bottom: 150px;
}

.table {
  margin: auto;
}

.row-table {
  width: 100%;
}

.resultat-td {
  text-align: right;
  width: 142px;
}

.table td,
.table th {
  border: 1px solid #dbdbdb;
  padding: 0.5em 0.75em;
  vertical-align: top;
}

.link-to-details {
  cursor: pointer;
}

.img-td {
  cursor: pointer;
  width: 20px;
  height: 18px;
  border: 1px solid lightgrey;
  padding: 2px;
  border-radius: 50%;
  margin-left: 8px;
}

.td-display {
  display: flex;
  position: relative;
}

.message {
  position: absolute;
  background-color: white;
  padding: 6px;
  border: 1px solid lightgrey;
  border-radius: 50px;
  font-size: 12px;
}

.td1 {
  top: 118px;
  right: 156px;
}

.td2 {
  top: 159px;
  right: 134px;
}

.td3 {
  top: 201px;
  right: 192px;
}

.td4 {
  top: 244px;
  right: 223px;
}
</style>
