<template>
  <div class="calendar-container">
    <card-cookie>
      <div>
        <div class="display-div">
          <h1>Sélectionner une période</h1>
          <b-field class="input-data">
            <b-datepicker
              placeholder="Sélectionner une période"
              v-model="dates"
              range
            >
            </b-datepicker>
          </b-field>
          <b-field>
            <b-button class="color" @click="getValues">Afficher </b-button>
          </b-field>
        </div>
      </div>
    </card-cookie>
    <div>
      <img
        src="../assets/images/img-header.svg"
        alt="image de l'application cookie menu"
        class="img-header"
      />
    </div>
  </div>
</template>

<script>
import CardCookie from './CardCookie.vue';

export default {
  components: {
    CardCookie,
  },
  data() {
    return {
      dates: [new Date(2020, 9, 1), new Date()],
    };
  },
  methods: {
    getValues() {
      const value1 = this.dates[0];
      const value2 = this.dates[1];
      const payLoad = {
        value1,
        value2,
      };
      // console.log(payLoad);
      this.$emit('search-result', payLoad);
    },
  },
};
</script>

<style scoped lang="scss">
.calendar-container {
  width: 90%;
  margin-left: 97px;
  margin-top: 111px;
  max-width: 1280px;
  min-height: 100%;
  display: flex;
}

.card-cookie {
  width: 90%;
  margin: auto;
  border-radius: 5px;
  padding: 15px;
  margin-left: 28px;
}

h1 {
  padding: 5px 0px 5px 0px;
}

.img-header {
  width: 700px;
}

.display-div {
  display: flex;
  justify-content: space-evenly;
}

.input-data {
  width: 32%;
  margin-right: 10px;
  margin-left: 10px;
}

.color {
  background-color: $primary;
  color: white;
  font-weight: bold;
}

@media screen and (min-width: 1024px) {
  .calendar-container[data-v-c216e1a0] {
    width: 80%;
    margin: auto;
    margin-top: 111px;
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .calendar-container[data-v-c216e1a0] {
    width: 90%;
    margin: auto;
    margin-top: 111px;
    display: flex;
    max-width: 1900px;
  }
}
</style>