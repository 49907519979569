const API_URL = `${process.env.VUE_APP_API_URL}`;

export function getFullContacts(dateFrom, dateTo, token){
    return  fetch(
        `${API_URL}/contacts/?startDate=${dateFrom}&endDate=${dateTo}`,
        {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
            'Content-type': 'application/json',
          }),
        },
      )
        .then((response) => response.json());
}