import Keycloak from 'keycloak-js';
import VueLogger from 'vuejs-logger';
import Vue from 'vue';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import App from './App.vue';
import VueRouter from 'vue-router';
import store from './store';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import Home from './pages/Home.vue';

Vue.use(Buefy);
Vue.use(VueLogger);
Vue.use(VueRouter);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

const AUTH_URL = process.env.VUE_APP_AUTH_URL;
const AUTH_CLIENTID = process.env.VUE_APP_AUTH_CLIENTID;
const AUTH_REALM = process.env.VUE_APP_AUTH_REALM;

let initOptions = {
  flow: 'hybrid',
  url: AUTH_URL,
  clientId: AUTH_CLIENTID,
  realm: AUTH_REALM,
  onLoad: 'login-required',
};

let keycloak = Keycloak(initOptions);

Vue.prototype.$keycloak = keycloak;

keycloak
  .init({ onLoad: initOptions.onLoad, checkLoginIframe: false })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      Vue.$log.info('Authenticated');

      new Vue({
        router,
        store,
        el: '#app',
        render: (h) => h(App, { props: { keycloak: keycloak } }),
      });
    }
    //localStorage.getItem("vue-token", keycloak.token);
    localStorage.setItem('vue-token', keycloak.token);
    localStorage.setItem('vue-refresh-token', keycloak.refreshToken);

    //Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          /*if (refreshed) {
        Vue.$log.info('Token refreshed' + refreshed);
      } else {
        Vue.$log.warn('Token not refreshed, valid for '
        + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }*/
        })
        .catch(() => {
          Vue.$log.error('Failed to refresh token');
        });
    }, 30000);
  })
  .catch(() => {
    Vue.$log.error('Authenticated Failed');
  });

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
    },
    { path: '/Version', component: () => import('./pages/Version.vue') },
    { path: '/ExportCsvPage', component: () => import('./pages/ExportCsvPage.vue') },
    { path: '*', redirect: '/' },
  ],
});
