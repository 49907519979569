<template>
  <div class="container-footer">
    <h2>@ {{ year }} - cookie.menu. Tous droits réservés</h2>
  </div>
</template>

<script>
export default {
  name: 'FooterCookie',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.container-footer {
  font-size: 10px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  position: fixed;
  background-color: #bd8508;
  padding: 12px;
  color: white;
  z-index: 1111;
}
</style>;
