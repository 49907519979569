<template>
  <section class="container">
    <card-cookie>
      <h1 class="color">Nombre de produits par restaurants</h1>
      <div>
        <line-chart
          :dataCollection="dataCollection"
          :styles="myStyles"
          :key="updateChart"
        ></line-chart>
      </div>
    </card-cookie>
  </section>
</template>

<script>
import LineChart from '@/components/LineChart.vue';
import CardCookie from '@/components/CardCookie.vue';

const API_URL = process.env.VUE_APP_API_URL;

export default {
  components: {
    LineChart,
    CardCookie,
  },
  props: ['details'],
  data() {
    return {
      updateChart: null,
      width: 600,
    };
  },
  computed: {
    dataCollection() {
      return {
        labels: this.getLabelsRestaurants(),
        datasets: [
          {
            label: 'produits',
            backgroundColor: '#f87979',
            data: this.getDataRestaurants(),
          },
        ],
      };
    },
    myStyles() {
      return {
        width: `${this.width}px`,
        margin: 'auto',
        position: 'relative'
      };
    },
  },
  watch: {
    dataCollection: {
      handler() {
        this.updateChart++;
      },
    },
  },
  methods: {
    getLabelsRestaurants() {
      return this.details.map((restaurant) => {
        return restaurant.name;
      });
    },
    getDataRestaurants() {
      return this.details
        .map((restaurant) => {
          let count = 0;
          for (const menu of restaurant.menus) {
            for (const meal of menu.meals) {
              const nbr = meal.dishes.length;
              count += nbr;
            }
          }
          return count;
        })
        .sort((a, b) => a - b);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 85%;
  margin: auto;
  padding-bottom: 50px;
}

.card-cookie {
  padding: 0px 0px 30px 0px;
  border-radius: 5px;
}

.color {
  background-color: $navbar;
  border-radius: 5px;
}

.title {
  text-align: center;
}

.button {
  color: white;
  background-color: $primary;
  margin-top: 20px;
  margin-bottom: 10px;
}

.button:hover {
  background-color: $secondary;
}
</style>
