<template>
  <section class="container">
    <card-cookie>
      <h1 class="color">Détails des restaurants</h1>
      <b-table :data="details">
        <b-table-column
          class="essai"
          field="name"
          label="Nom"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          <a :href="`${url}/restaurants/${props.row._id}`" target="_blank">{{
            props.row.name
          }}</a>
        </b-table-column>

        <b-table-column
          field="createdAt"
          label="Date de création"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          {{ formatDate(props.row.createdAt) }}
        </b-table-column>

        <b-table-column
          field="updatedAt"
          label="Date de la dernière modification"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          {{ formatDate(props.row.updatedAt) }}
        </b-table-column>

        <b-table-column
          field="description"
          label="Description"
          width="220px"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          {{ props.row.description }}
        </b-table-column>

        <b-table-column
          field="contactInfos.address.city"
          label="Ville"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          {{ props.row.contactInfos.address.city }}
        </b-table-column>

        <b-table-column
          field="contactInfos.address.postCode"
          label="Code postal"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          {{ props.row.contactInfos.address.postCode }}
        </b-table-column>

        <b-table-column
          field="menus"
          label="Menus"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          <a
            :href="`${url}/restaurants/${props.row._id}#${menu._id}`"
            v-for="menu in props.row.menus"
            :key="menu._id"
            target="_blank"
          >
            {{ menu.name }}
          </a>
        </b-table-column>

        <b-table-column
          field="menus"
          label="Catégories"
          width="250px"
          :td-attrs="columnTdAttrs"
          v-slot="props"
          ><div v-for="menu in props.row.menus" :key="menu._id">
            <div v-for="meal in menu.meals" :key="meal._id">
              {{ meal.name }}
            </div>
          </div>
        </b-table-column>

        <b-table-column
          field="menus"
          label="Produits"
          :td-attrs="columnTdAttrs"
          v-slot="props"
        >
          {{ nbDishesRestaurant(props.row) }}
        </b-table-column>
      </b-table>
    </card-cookie>
  </section>
</template>

<script>
import CardCookie from './CardCookie.vue';
import LineChart from '@/components/LineChart.vue';

const API_URL = process.env.VUE_APP_API_URL;

export default {
  components: {
    CardCookie,
    LineChart,
  },
  props: {
    details: {
      type: Array,
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_BACK_URL,
    };
  },
  methods: {
    nbDishesRestaurant(resto) {
      let name = resto.name;
      let count = 0;
      for (const menu of resto.menus) {
        for (const meal of menu.meals) {
          const nbr = meal.dishes.length;
          count += nbr;
        }
      }
      //this.label = count;
      //this.data = name;
      return count;
    },
    dateThAttrs(column) {
      return column.label === 'Date'
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: 'has-text-success',
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === 'Total') {
        if (column.label === 'ID') {
          return {
            colspan: 4,
            class: 'has-text-weight-bold',
            style: {
              'text-align': 'left !important',
            },
          };
        } else if (column.label === 'Gender') {
          return {
            class: 'has-text-weight-semibold',
          };
        } else {
          return {
            style: { display: 'none' },
          };
        }
      }
      return null;
    },
    methodToString(data) {
      if (data === Boolean) {
        return data.toString();
      }
    },
    formatDate(dateAsString) {
      let date = new Date(dateAsString);
      return (
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/main.scss';

.title {
  text-align: center;
}

.container {
  width: 85%;
  margin: auto;
  z-index: 1;
}

table td .essai {
  width: 251px;
}

.color {
  background-color: $navbar;
  margin-bottom: 25px;
  border-radius: 5px;
}
</style>
