<template>
  <section class="container">
    <card-cookie>
      <section>
        <l-map
          :center="center"
          :zoom="zoom"
          class="map"
          ref="map"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
        >
          <l-tile-layer :url="url"> </l-tile-layer>
          <restaurants-map
            v-for="marker in markers"
            :key="marker.id"
            :marker="marker"
          >
          </restaurants-map>
        </l-map>
      </section>
    </card-cookie>
    <div class="div-nbr-restaurants">
      <p class="nbr-restaurants">
        Nbr de restaurants affichés : {{ nbrRestaurant }}
      </p>
      <p class="nbr-restaurants">
        Nbr de restaurants qui ne sont pas affichés : {{ tableau.length }}
      </p>
      <p class="nbr-restaurants">
        Nbr de restaurant au total : {{ tableau.length + nbrRestaurant }}
      </p>
    </div>
  </section>
</template>

<script>
import { LMap, LTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import RestaurantsMap from './RestaurantsMap.vue';
import CardCookie from './CardCookie.vue';

export default {
  props: {
    details: {
      type: Array,
    },
  },
  components: {
    LMap,
    LTileLayer,
    RestaurantsMap,
    CardCookie,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: [46.71109, 1.7191036],
      zoom: 6,
      tableau: [],
      nbrRestaurant: [],
    };
  },
  computed: {
    markers() {
      const selectionRestaurants = this.details.map((restaurant) => {
        return {
          name: restaurant.name,
          id: restaurant._id,
          coordinates:
            restaurant.contactInfos &&
            restaurant.contactInfos.address &&
            restaurant.contactInfos.address.location &&
            restaurant.contactInfos.address.location.coordinates
              ? restaurant.contactInfos.address.location.coordinates.reverse()
              : [],
        };
      });
      console.log('u', selectionRestaurants.id);
      const restaurantCoordinates = selectionRestaurants.filter(
        (marker) => marker.coordinates.length > 1,
      );
      this.nbrRestaurant = restaurantCoordinates.length;
      this.tableau = selectionRestaurants.filter(
        (marker) => marker.coordinates.length < 1,
      );
      return restaurantCoordinates;
    },
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
  },
};
/* markers() {
      const selectionRestaurants = this.details.map((restaurant) => {
        return {
          id: restaurant._id,
          coordinates: restaurant.contactInfos.address.location.coordinates.reverse(),
        };
      });
      const restaurantCoordinates = selectionRestaurants.filter(
        (marker) => marker.coordinates.length > 1,
      );
      this.nbrRestaurant = restaurantCoordinates.length;
      this.tableau = selectionRestaurants.filter(
        (maker) => maker.coordinates.length < 1,
      );
      return restaurantCoordinates;
    },*/
</script>

<style scoped lang="scss">
.container {
  padding-bottom: 130px;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.card-cookie {
  padding: 0px 0px 30px 0px;
  border-radius: 5px;
  height: 692px;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  touch-action: none;
  height: 645px;
  width: 83%;
  margin-top: 19px;
  margin-left: 19px;
}

.nbr-restaurants {
  font-weight: 500;
}

.div-nbr-restaurants {
  background-color: white;
  width: 100%;
  margin: auto;
  margin-left: 46px;
  margin-top: 30px;
  padding: 12px;
  border-radius: 5px;
}

@media screen and (min-width: 1200px) {
  .card-cookie {
    height: 692px;
  }

  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom[data-v-1b518aa0] {
    touch-action: none;
    height: 645px;
    width: 96%;
  }
}

@media screen and (min-width: 1439px) {
  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom[data-v-1b518aa0] {
    touch-action: none;
    height: 645px;
    width: 83%;
    margin-top: 19px;
    margin-left: 19px;
  }
}

@media screen and (min-width: 1918px) {
  .card-cookie {
    height: 692px;
  }

  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom[data-v-1b518aa0] {
    touch-action: none;
    height: 645px;
    width: 83%;
  }
}
</style>